import React from "react"
import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core"
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    notification: {
      background: "#FFECB3",
      color: "black",
      fontSize: "0.9rem",
      textAlign: "left",
      padding: theme.spacing(1.4),
      margin: theme.spacing(-4, -4, 2, -4),
      borderColor: "#ffd899",
      border: "solid",
    },
    warning: {
      color: "#FF8F00",
    },
  })
})

export function DeprecationBanner() {
  const classes = useStyles()
  return (
    <div className={classes.notification}>
      <Grid container spacing={2}>
        <Grid item>
          <ReportProblemIcon className={classes.warning} />
        </Grid>
        <Grid item xs={11}>
          <span style={{ fontSize: "1.2rem" }}>Important Announcement!</span> <br /> <br/>
          <div>
            This deprecated version of TLS Protect for Kubernetes, originally known as Jetstack Secure, will be PERMANENTLY SHUTDOWN on May 19, 2025. If
            you're still using this version, please work with your CyberArk/Venafi account team to transition to the{" "}
            <a href="https://venafi.com/tls-protect-for-kubernetes/" target="blank">
              current version
            </a>{" "}
            of TLS Protect for Kubernetes.
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
